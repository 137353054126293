import React, { useState } from "react";
import "./footer.css";
import Logo from "../../assets/AzeezCuts.png";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(
    new Date().getFullYear().toString()
  );
  return (
    <div className="footer">
      <img className="footer-logo" src={Logo} alt="Logo" />
      <div>
        <h2>Copyright {currentYear} - AzeezCuts by ASDesigns</h2>
      </div>
    </div>
  );
};

export default Footer;
