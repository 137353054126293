import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLock,
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import "./login.css";

function Login() {
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [error, setError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      const loginResponse = await login(formData);

      if (loginResponse) {
        // Redirect to homepage after successful login
        navigate("/");
      } else {
        setError("Login failed. Please check your credentials.");
      }
    } catch (err) {
      setError("Login failed. Please try again.");
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="signin">
      <div className="signin-text">
        <h1>Sign In</h1>
        <h2>Welcome back to the kingdom</h2>
        <p>
          Sign in to your account and continue your grooming journey. Enjoy
          exclusive benefits and easy booking.
        </p>
      </div>
      <form className="form-signin" onSubmit={handleSubmit}>
        <div className="flex-column">
          <label>Email </label>
        </div>
        <div className="inputForm">
          <FontAwesomeIcon icon={faEnvelope} size="1x" color="blue" />
          <input
            className="input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>

        <div className="flex-column">
          <label>Password </label>
        </div>
        <div className="inputForm">
          <FontAwesomeIcon icon={faLock} size="1x" color="blue" />
          <input
            className="input"
            type={showPassword ? "text" : "password"}
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            size="1x"
            color="blue"
            onClick={() => setShowPassword(!showPassword)}
            style={{ cursor: "pointer", marginLeft: "10px" }}
          />
        </div>

        <button className="button-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Signing in..." : "Sign In"}
        </button>
        {error && <p className="error">{error}</p>}
        <div className="flex-row">
          <p className="p">
            Don&apos;t have an account?{" "}
            <span className="span">
              <Link to="/register"> Sign Up</Link>
            </span>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Login;
