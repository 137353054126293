// Navbar.jsx
import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "animate.css";
import "./navbar.css";
import Logo from "../../assets/AzeezCuts.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const { auth, logout, loading } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsMobile(!isMobile);
  };

  const handleLinkClick = (event, hash) => {
    event.preventDefault();
    if (location.pathname !== "/home") {
      navigate(`/home${hash}`);
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    } else {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
      window.history.pushState(null, null, hash);
    }
    setIsMobile(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsSticky(window.scrollY > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="navbar-spacer"></div>
      <div className={`navbar-container ${isSticky ? "sticky" : ""}`}>
        <nav className="navbar">
          <Link to="/home" onClick={(e) => handleLinkClick(e, "#home")}>
            <img className="navbar-logo" src={Logo} alt="Logo" />
          </Link>
          <ul
            className={
              isMobile
                ? "mobile-nav-bg nav-links-mobile animate__animated animate__fadeInRight"
                : "nav-links"
            }
          >
            {auth && auth.role === "admin" && (
              <Link to="/admin">
                <li className="admin-btn">Admin</li>
              </Link>
            )}
            <Link to="/home" onClick={(e) => handleLinkClick(e, "#home")}>
              <li>Home</li>
            </Link>
            <Link to="/home" onClick={(e) => handleLinkClick(e, "#services")}>
              <li>Services</li>
            </Link>
            <Link to="/home" onClick={(e) => handleLinkClick(e, "#gallery")}>
              <li>Gallery</li>
            </Link>
            {auth ? (
              <>
                <Link to="/bookings">
                  <li className="booking-btn">Bookings</li>
                </Link>
                <li className="signout" onClick={logout}>
                  Sign Out
                </li>
              </>
            ) : (
              <>
                <Link to="/register">
                  <li>Sign Up</li>
                </Link>
                <Link to="/login">
                  <li>Login</li>
                </Link>
              </>
            )}
            <Link to="/home" onClick={(e) => handleLinkClick(e, "#contact")}>
              <li>Contact</li>
            </Link>
          </ul>
          <div className="menu-icon" onClick={handleToggle}>
            {isMobile ? (
              <FontAwesomeIcon icon={faTimes} size="2x" color="blue" />
            ) : (
              <FontAwesomeIcon icon={faBars} size="2x" color="blue" />
            )}
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
