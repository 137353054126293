import React, { useState, useEffect } from "react";
import axios from "axios";
import "./ContactForm.css"; // Optional: Create a CSS file for styling

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submission status

  useEffect(() => {
    let timer;
    if (success) {
      // Set a timer to clear the success message after 5 seconds
      timer = setTimeout(() => setSuccess(""), 5000);
    }
    // Cleanup the timer if the component unmounts before the timer completes
    return () => clearTimeout(timer);
  }, [success]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Set submitting to true before starting the request

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/contact`,
        {
          name,
          email,
          message,
        }
      );
      setSuccess("Message sent successfully!");
      setError("");
      setName("");
      setEmail("");
      setMessage("");
    } catch (error) {
      setError(error.response?.data?.error || "Failed to send message.");
      setSuccess("");
    } finally {
      setIsSubmitting(false); // Reset submitting status after request completes
    }
  };

  return (
    <div className="form-card1">
      <div className="form-card2">
        <form className="form" onSubmit={handleSubmit}>
          <p className="form-heading">Get In Touch</p>
          {error && <p className="error">{error}</p>}
          {success && <p className="success">{success}</p>}

          <div className="form-field">
            <input
              required
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className="input-field"
              type="text"
            />
          </div>

          <div className="form-field">
            <input
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="input-field"
              type="email"
            />
          </div>

          <div className="form-field">
            <textarea
              required
              placeholder="Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              cols={30}
              rows={10}
              className="input-field"
            />
          </div>

          <button
            type="submit"
            className="sendMessage-btn"
            disabled={isSubmitting} // Disable button if submitting
          >
            {isSubmitting ? "Sending..." : "Send Message"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
