import React, { useState, useEffect } from "react";
import Calendar from "react-calendar";
import axios from "axios";
import "react-calendar/dist/Calendar.css";
import "./bookings.css";

function Bookings() {
  const [selectedDate, setSelectedDate] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const [formData, setFormData] = useState({
    time: "",
    location: "",
    service: "",
    phoneNumber: "", // Add phone number to the form data
  });
  const [bookingStatus, setBookingStatus] = useState(null);
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  useEffect(() => {
    // Fetch user data with token
    const fetchUser = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/auth/user`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data.user); // Set user data
      } catch (err) {
        console.error("Error fetching user:", err);
        setBookingStatus("Unauthorized: Please log in.");
      }
    };

    fetchUser();
  }, []);

  const disablePastDates = ({ date }) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0);
  };

  useEffect(() => {
    if (selectedDate) {
      const fetchTimeSlots = async () => {
        try {
          const token = localStorage.getItem("token"); // Get the token

          const response = await axios.get(
            `${process.env.REACT_APP_API_URL}/api/appointments/slots`,
            {
              params: { date: selectedDate.toLocaleDateString("en-GB") },
              headers: {
                Authorization: `Bearer ${token}`, // Include the token in the request header
              },
            }
          );
          setTimeSlots(response.data);
        } catch (err) {
          console.error("Error fetching time slots:", err);
        }
      };

      fetchTimeSlots();
    }
  }, [selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setTimeSlots([]);
    setFormData({ ...formData, time: "" });
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true

    // Ensure all fields are filled
    if (
      !selectedDate ||
      !formData.time ||
      !formData.location ||
      !formData.service ||
      !formData.phoneNumber ||
      !user
    ) {
      setBookingStatus("All fields are required");
      setIsLoading(false); // Reset loading state
      return;
    }

    // Format the date in dd-mm-yyyy
    const formattedDate = `${selectedDate
      .getDate()
      .toString()
      .padStart(2, "0")}-${(selectedDate.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${selectedDate.getFullYear()}`;

    try {
      const token = localStorage.getItem("token");

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/appointments`,
        {
          date: formattedDate, // Use the formatted date here
          time: formData.time,
          location: formData.location,
          service: formData.service,
          phoneNumber: formData.phoneNumber,
          userId: user.user.id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Booking Created!");

      setBookingStatus(
        "Creating invoice for appointment Deposit (Keep an eye on your email!). Appointment Status: Pending admin approval"
      );
    } catch (err) {
      console.error("Error booking appointment:", err);
      setBookingStatus("Failed to book appointment. Please try again.");
    } finally {
      setIsLoading(false); // Reset loading state after booking attempt
    }
  };

  return (
    <div className="booking">
      <div className="booking-intro">
        <div className="booking-text">
          <h1>Book an Appointment</h1>
          <h2>Your throne awaits</h2>
          <p>
            Ready to elevate your look? Choose your desired service, select your
            preferred date and time, and let Azeez Cuts transform your hair.
            Book your appointment today and step into a world of style.
          </p>
          <p style={{ fontStyle: "italic" }}>
            Note: Bookings must be made at least 24hrs in advance. Appointment
            deposits are non-refundable 24hrs to appointment time{" "}
          </p>
        </div>

        <div className="calendar-container">
          <Calendar
            onChange={handleDateChange}
            value={selectedDate}
            tileDisabled={disablePastDates} // Disable past dates
          />
        </div>
      </div>
      <div className="appointment">
        {selectedDate && (
          <>
            <h3>Available Time Slots</h3>
            <div className="time-slots">
              {timeSlots.length > 0 ? (
                timeSlots.map((slot) => (
                  <button
                    key={slot}
                    onClick={() => setFormData({ ...formData, time: slot })}
                    className={formData.time === slot ? "selected" : ""}
                  >
                    {slot}
                  </button>
                ))
              ) : (
                <p>No available slots for this date.</p>
              )}
            </div>
          </>
        )}

        {formData.time && (
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="location"
              value={formData.location}
              onChange={handleChange}
              placeholder="Enter your location"
              required
            />
            <textarea
              type="text"
              name="service"
              value={formData.service}
              onChange={handleChange}
              placeholder="Describe the service(s) you would like"
              rows={5}
              required
            />
            <input
              type="text"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              placeholder="Enter your phone number" // New phone number input
              required
            />
            <button type="submit" disabled={isLoading}>
              {isLoading ? "Submitting..." : "Submit Booking Request"}
            </button>
          </form>
        )}
      </div>

      {bookingStatus && <p className="booking-status">{bookingStatus}</p>}
    </div>
  );
}

export default Bookings;
