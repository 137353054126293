import React from "react";
import "./barberpole.css";

const BarberPole = () => {
  return (
    <div className="barber-pole-container">
      <div className="barber-pole-ball top"></div> {/* Top ball handle */}
      <div className="barber-pole">
        <div className="barber-pole-stripe red"></div>
        <div className="barber-pole-stripe white"></div>
        <div className="barber-pole-stripe blue"></div>
      </div>
      <div className="barber-pole-ball bottom"></div> {/* Bottom ball handle */}
    </div>
  );
};

export default BarberPole;
