import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const PrivateRoute = ({ children, adminOnly, ...rest }) => {
  const { auth, loading } = useContext(AuthContext);

  if (loading) {
    // Optionally return a loading spinner or null while loading
    return <div>Loading...</div>; // Replace with a spinner or loading indicator if needed
  }

  // Check if auth is null or if auth.user is undefined
  if (!auth) {
    return <Navigate to="/login" replace />;
  }

  // Check if adminOnly is true and the user's role is not "admin"
  if (adminOnly && auth.role !== "admin") {
    console.log("User is not an admin, access denied."); // Debugging log
    return <Navigate to="/" replace />;
  }

  // If all checks pass, render the children
  return children;
};

export default PrivateRoute;
