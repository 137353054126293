import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Login from "./pages/login/login";
import Register from "./pages/register/register";
import Bookings from "./pages/bookings/bookings";
import AdminDashboard from "./pages/admin/admin";
import Home from "./pages/home/home";
import NavBar from "./components/navbar/navbar";
import Footer from "./components/footer/footer";
import PrivateRoute from "./components/PrivateRoute";

function App() {
  return (
    <Router>
      <NavBar />
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/bookings"
          element={
            <PrivateRoute>
              <Bookings />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin"
          element={
            <PrivateRoute adminOnly={true}>
              <AdminDashboard />
            </PrivateRoute>
          }
        />
        <Route index path="/" element={<Home />} />
        <Route index path="/home" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
