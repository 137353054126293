import React, { useEffect, useRef, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext.jsx";
import { Link } from "react-router-dom";
import anime from "animejs";
import { useSpring, animated } from "react-spring";
import "animate.css";
import "./home.css";
import Logo from "../../assets/AzeezCuts.png";
import ContactForm from "../../components/contact/ContactForm.jsx";
import service1 from "../../assets/service1.jpg";
import service2 from "../../assets/service2.jpg";
import service3 from "../../assets/service3.jpg";
import service4 from "../../assets/service4.jpg";
import ScrollToTopButton from "../../components/topButton/ScrollToTopButton.jsx";
import BarberPole from "../../components/barberpole/barberpole.jsx";
import gallery1 from "../../assets/gallery1.jpeg";
import gallery2 from "../../assets/gallery2.jpeg";
import gallery3 from "../../assets/gallery3.jpeg";
import gallery4 from "../../assets/gallery4.jpeg";
import gallery5 from "../../assets/gallery5.jpeg";
import gallery6 from "../../assets/gallery6.jpeg";
import gallery7 from "../../assets/gallery7.jpeg";
import gallery8 from "../../assets/gallery8.jpeg";
import gallery9 from "../../assets/gallery9.jpeg";
import gallery10 from "../../assets/gallery10.jpeg";

const Home = () => {
  const { auth } = useContext(AuthContext);
  auth
    ? console.log("User authenticated")
    : console.log("User Not Authenicated");
  //smoothscrolling
  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    };

    // Call the function on mount and when hash changes
    handleHashChange();
    window.addEventListener("hashchange", handleHashChange);

    return () => window.removeEventListener("hashchange", handleHashChange);
  }, []);

  //Looping animation
  const headingRef = useRef(null);

  useEffect(() => {
    // Get all the spans inside the heading
    const letters = headingRef.current.querySelectorAll("span");

    // Anime.js animation configuration
    anime({
      targets: letters,
      opacity: [0, 1],
      translateY: [20, 0],
      easing: "easeOutExpo",
      duration: 1000,
      delay: anime.stagger(100), // Delay between each letter
      loop: true,
    });
  }, []);

  // Split text into individual letters
  const text = "AzeezCuts";
  const splitText = text.split("").map((char, index) => (
    <span key={index} style={{ display: "inline-block" }}>
      {char}
    </span>
  ));

  //Parallax

  useEffect(() => {
    let scrollTop = 0;
    let ticking = false;

    const handleScroll = () => {
      scrollTop = window.pageYOffset;
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const parallaxElements = document.querySelectorAll(
            ".parallax, .reverse-parallax"
          );
          parallaxElements.forEach((element) => {
            const speed = parseFloat(element.getAttribute("data-speed"));
            element.style.backgroundPositionY = `${scrollTop * speed}px`;
          });
          ticking = false;
        });
        ticking = true;
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  //PhotoGallery

  const photos = [
    gallery1,
    gallery2,
    gallery3,
    gallery4,
    gallery5,
    gallery6,
    gallery7,
    gallery8,
    gallery9,
    gallery10,
  ];

  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const expandAnimation = useSpring({
    opacity: isOpen ? 1 : 0,
    transform: isOpen ? `scale(1)` : `scale(0.9)`,
    config: { tension: 200, friction: 15 },
  });

  const openImage = (photo) => {
    setSelectedPhoto(photo);
    setIsOpen(true);
  };

  const closeImage = () => {
    setIsOpen(false);
    setTimeout(() => setSelectedPhoto(null), 300);
  };

  return (
    <div>
      <div>
        <ScrollToTopButton />
      </div>
      <div className="parallax" data-speed="0.8">
        <div id="home" className="content-wrapper">
          <div className="intro">
            <BarberPole />
            <div className="hero">
              <img
                src={Logo}
                className="animate__animated animated-jack-pulse"
                alt=""
              />
              <h1 ref={headingRef} className="looping_text">
                {splitText}
              </h1>
            </div>
            <BarberPole />
          </div>
          <p className="intro-text">
            Tired of looking average? Azeez Cuts is your ticket to head-turning
            hair bringing the barbershop to you. Elevate your game. Book Azeez.
            Sharp looks, Fresh vibes
            <br />
          </p>
        </div>
      </div>
      <div id="services" className="content services">
        <h2>Services</h2>
        <div className="service-grid">
          <div className="service-item">
            <div className="de-box-a">
              <div className="d-image">
                <img src={service1} alt="" />
              </div>
              <div className="d-deco-1"></div>
              <div className="d-deco-2"></div>
            </div>
            <h4>Haircuts</h4>
          </div>

          <div className="service-item">
            <div className="de-box-a">
              <div className="d-image">
                <img src={service2} alt="" />
              </div>
              <div className="d-deco-1"></div>
              <div className="d-deco-2"></div>
            </div>

            <h4>Beard</h4>
          </div>

          <div className="service-item">
            <div className="de-box-a">
              <div className="d-image">
                <img src={service3} alt="" />
              </div>
              <div className="d-deco-1"></div>
              <div className="d-deco-2"></div>
            </div>

            <h4>Dyeing</h4>
          </div>

          <div className="service-item">
            <div className="de-box-a">
              <div className="d-image">
                <img src={service4} alt="" />
              </div>
              <div className="d-deco-1"></div>
              <div className="d-deco-2"></div>
            </div>

            <h4>Styling</h4>
          </div>
        </div>

        <div className="service-text">
          <h3>Your head, your kingdom. Rule it in style</h3>
          <p>
            From precision cuts to beard mastery, we've got your grooming game
            covered. Experience the Azeez Cuts difference with our range of
            services. Prices vary based on location, but one thing's for sure:
            you'll look like royalty.
          </p>
        </div>

        <Link to="/bookings" className="button">
          Book Now
        </Link>
      </div>
      <div id="gallery" className="reverse-parallax " data-speed="-0.3">
        <div className=" gallery">
          <h2>Gallery</h2>
          <h3>Don't just take our word for it. See the proof.</h3>
          <div>
            <div className="grid-container">
              {photos.map((photo, index) => (
                <div
                  className="image-container"
                  key={index}
                  onClick={() => openImage(photo)}
                >
                  <img src={photo} alt="gallery" />
                </div>
              ))}
            </div>
            {selectedPhoto && (
              <animated.div
                className="expanded-image-container"
                style={expandAnimation}
                onClick={closeImage}
              >
                <img
                  className="expanded-image"
                  src={selectedPhoto}
                  alt="Expanded View"
                />
              </animated.div>
            )}
          </div>
        </div>
      </div>
      <div id="contact" className="content contact">
        <h2>Contact Us</h2>
        <p className="contact-text">
          Have questions, need more details, or simply want to book your
          appointment? Contact us today. We're here to assist you and make your
          grooming experience unforgettable
        </p>
        <ContactForm />
      </div>
    </div>
  );
};

export default Home;
