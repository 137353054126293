import React, { useState, useContext } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faLock,
  faEnvelope,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, Link } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import "./register.css";

function Register() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");

    try {
      // Register the user
      const registerResponse = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/users/register`,
        formData
      );

      if (registerResponse.status === 200) {
        // Automatically log in the user
        const loginSuccess = await login({
          email: formData.email,
          password: formData.password,
        });

        if (loginSuccess) {
          navigate("/"); // Redirect to homepage
        } else {
          setError("Login failed after registration. Please try logging in.");
        }
      } else {
        setError("Registration failed. Please try again.");
      }
    } catch (err) {
      setError("Registration failed. Please try again.");
      console.error(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Function to toggle password visibility
  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="register">
      <div className="register-text">
        <h1>Register</h1>
        <h2>Join the Azeez Cuts family</h2>
        <p>
          Ready to experience the ultimate grooming experience? Register now and
          become a member of our exclusive community. Enjoy exclusive offers,
          priority booking, and access to the latest trends.
        </p>
      </div>

      <form className="form-register" onSubmit={handleSubmit}>
        <div className="flex-column">
          <label>Name </label>
        </div>
        <div className="inputForm">
          <FontAwesomeIcon icon={faUser} size="1x" color="blue" />
          <input
            className="input"
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Name"
            required
          />
        </div>

        <div className="flex-column">
          <label>Email </label>
        </div>
        <div className="inputForm">
          <FontAwesomeIcon icon={faEnvelope} size="1x" color="blue" />
          <input
            className="input"
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
          />
        </div>

        <div className="flex-column">
          <label>Password </label>
        </div>
        <div className="inputForm">
          <FontAwesomeIcon icon={faLock} size="1x" color="blue" />
          <input
            className="input"
            type={showPassword ? "text" : "password"} // Toggle between 'password' and 'text'
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Password"
            required
          />
          <FontAwesomeIcon
            icon={showPassword ? faEyeSlash : faEye}
            size="1x"
            color="blue"
            onClick={toggleShowPassword}
            className="password-toggle-icon"
          />
        </div>

        <button className="button-submit" type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Processing..." : "Sign Up"}
        </button>
        {error && <p className="error">{error}</p>}
        <div className="flex-row">
          <p className="p">
            Already have an account?{" "}
            <span className="span">
              <Link to="/login">Sign In</Link>
            </span>
          </p>
        </div>
      </form>
    </div>
  );
}

export default Register;
