import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./admin.css";

function AdminDashboard() {
  const [pendingAppointments, setPendingAppointments] = useState([]);
  const [confirmedAppointments, setConfirmedAppointments] = useState([]);
  const [completedAppointments, setCompletedAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [depositAmount, setDepositAmount] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deletingAppointment, setDeletingAppointment] = useState(false);
  const [cancellingAppointment, setCancellingAppointment] = useState(false);
  const [sendingInvoice, setSendingInvoice] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
          return;
        }

        const appointmentsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/admin/appointments`,
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const pending = appointmentsResponse.data.filter(
          (appointment) => appointment.status === "Pending"
        );
        const confirmed = appointmentsResponse.data.filter(
          (appointment) => appointment.status === "Confirmed"
        );
        const completed = appointmentsResponse.data.filter(
          (appointment) =>
            appointment.status === "Completed" ||
            appointment.status === "Cancelled"
        );

        setPendingAppointments(pending);
        setConfirmedAppointments(confirmed);
        setCompletedAppointments(completed);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
          navigate("/login");
        } else {
          setError("Failed to fetch appointments");
        }
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchAppointments();
  }, [navigate]);

  // Handle appointment deletion
  const handleDeleteAppointment = async (appointmentId) => {
    if (window.confirm("Are you sure you want to delete this appointment?")) {
      setDeletingAppointment(true);
      try {
        const token = localStorage.getItem("token");
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/admin/appointments/${appointmentId}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setPendingAppointments((prev) =>
          prev.filter((appointment) => appointment._id !== appointmentId)
        );
        alert("Appointment deleted successfully.");
      } catch (err) {
        alert("Failed to delete appointment.");
        console.error(err);
      } finally {
        setDeletingAppointment(false);
      }
    }
  };

  // Handle cancel appointment
  const handleCancelAppointment = async (appointmentId) => {
    if (window.confirm("Are you sure you want to cancel this appointment?")) {
      setCancellingAppointment(true);
      try {
        const token = localStorage.getItem("token");
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/admin/appointments/${appointmentId}/cancel`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        setConfirmedAppointments((prev) =>
          prev.filter((appointment) => appointment._id !== appointmentId)
        );
        alert("Appointment canceled successfully.");
      } catch (err) {
        alert("Failed to cancel appointment.");
        console.error(err);
      } finally {
        setCancellingAppointment(false);
      }
    }
  };

  // Handle marking an appointment as completed
  const handleCompleteAppointment = async (appointmentId) => {
    if (
      window.confirm(
        "Are you sure you want to mark this appointment as completed?"
      )
    ) {
      try {
        const token = localStorage.getItem("token");

        // Mark the appointment as completed in the backend
        const response = await axios.put(
          `${process.env.REACT_APP_API_URL}/api/admin/appointments/${appointmentId}/complete`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );

        const completedAppointment = response.data;

        // Remove from confirmed and add to completed appointments
        setConfirmedAppointments((prev) =>
          prev.filter((appointment) => appointment._id !== appointmentId)
        );
        setCompletedAppointments((prev) => [...prev, completedAppointment]);

        alert("Appointment marked as completed.");
      } catch (err) {
        alert("Failed to mark appointment as completed.");
        console.error(err);
      }
    }
  };

  // Handle invoice creation
  const handleSendInvoice = async (appointmentId) => {
    if (!depositAmount || !totalAmount) {
      alert("Please enter both deposit and total amounts.");
      return;
    }

    setSendingInvoice(true);
    try {
      const token = localStorage.getItem("token");
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/admin/send-invoice`,
        {
          appointmentId,
          amount: Number(depositAmount),
          total: Number(totalAmount),
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      alert("Invoice sent successfully!");
    } catch (err) {
      alert("Failed to send invoice.");
      console.error(err);
    } finally {
      setSendingInvoice(false);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="admin">
      <h1>Admin Dashboard</h1>

      {/* Pending Appointments */}
      <h2>Pending Appointments</h2>
      <table>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Customer Number</th>
            <th>Service</th>
            <th>Location</th>
            <th>Date</th>
            <th>Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {pendingAppointments.map((appointment) => (
            <tr key={appointment._id}>
              <td>{appointment.user.name}</td>
              <td>{appointment.phoneNumber}</td>
              <td>{appointment.service}</td>
              <td>{appointment.location}</td>
              <td>{new Date(appointment.date).toLocaleDateString()}</td>
              <td>{appointment.time}</td>
              <td>
                <button onClick={() => setSelectedAppointment(appointment)}>
                  Send Invoice
                </button>
                <button
                  className="cancelled-btn"
                  onClick={() => handleDeleteAppointment(appointment._id)}
                  disabled={deletingAppointment}
                >
                  {deletingAppointment ? "Deleting..." : "Delete"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Invoice Form */}
      {selectedAppointment && (
        <div className="invoice-form">
          <h3>Send Invoice for Appointment</h3>
          <p>
            Appointment with {selectedAppointment.user.name} on{" "}
            {new Date(selectedAppointment.date).toLocaleDateString()} at{" "}
            {selectedAppointment.location}
          </p>
          <label>
            Deposit Amount:
            <input
              type="number"
              value={depositAmount}
              onChange={(e) => setDepositAmount(e.target.value)}
            />
          </label>
          <label>
            Total Appointment Cost:
            <input
              type="number"
              value={totalAmount}
              onChange={(e) => setTotalAmount(e.target.value)}
            />
          </label>
          <button
            className="confirmed-btn"
            onClick={() => handleSendInvoice(selectedAppointment._id)}
            disabled={sendingInvoice}
          >
            {sendingInvoice ? "Sending..." : "Send Invoice"}
          </button>
        </div>
      )}

      {/* Confirmed Appointments */}
      <h2>Confirmed Appointments</h2>
      <table>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Customer Number</th>
            <th>Service</th>
            <th>Location</th>
            <th>Date</th>
            <th>Time</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {confirmedAppointments.map((appointment) => (
            <tr key={appointment._id}>
              <td>{appointment.user.name}</td>
              <td>{appointment.phoneNumber}</td>
              <td>{appointment.service}</td>
              <td>{appointment.location}</td>
              <td>{new Date(appointment.date).toLocaleDateString()}</td>
              <td>{appointment.time}</td>
              <td>
                <button
                  className="completed-btn"
                  onClick={() => handleCompleteAppointment(appointment._id)}
                >
                  Mark as Completed
                </button>
                <button
                  className="cancelled-btn"
                  onClick={() => handleCancelAppointment(appointment._id)}
                  disabled={cancellingAppointment}
                >
                  {cancellingAppointment ? "Cancelling..." : "Cancel"}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Completed Appointments */}
      <h2>Completed/Cancelled Appointments</h2>
      <table>
        <thead>
          <tr>
            <th>Customer Name</th>
            <th>Customer Number</th>
            <th>Service</th>
            <th>Location</th>
            <th>Date</th>
            <th>Time</th>
            <th>Status</th>
            <th>Total Paid</th>
          </tr>
        </thead>
        <tbody>
          {completedAppointments.map((appointment) => (
            <tr key={appointment._id}>
              <td>{appointment.user.name}</td>
              <td>{appointment.phoneNumber}</td>
              <td>{appointment.service}</td>
              <td>{appointment.location}</td>
              <td>{new Date(appointment.date).toLocaleDateString()}</td>
              <td>{appointment.time}</td>
              <td>{appointment.status}</td>
              <td>
                {appointment.status === "Cancelled"
                  ? "N/A"
                  : appointment.totalAmount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default AdminDashboard;
